<template>
  <BModal
    id="modal-auto-topup"
    ref="modal-auto-topup"
    hide-footer
    size="md"
    hide-header
    centered
    no-close-on-esc
    no-close-on-backdrop
    body-class="p-0"
    @show="showModal"
    @hide="hideModal"
  >
    <ValidationObserver
      ref="form"
      #default="{ invalid }"
    >
      <div class="px-[24px] pt-[16px] pb-[10px] border-b-[#E2E2E2]">
        <div class="text-[#333333] mt-[10px] font-semibold text-[16px]">
          Pengaturan Auto Topup
        </div>
      </div>
      <hr>
      <div class="px-[24px] mt-[20px]">
        <div class="grid-modal">
          <div class="flex flex-col gap-[16px]">
            <div class="text-nowrap">
              Set Auto Topup
            </div>
            <div class="text-nowrap py-[9px]">
              Nama kartu
            </div>
          </div>
          <div>
            <div class="flex flex-col gap-[16px]">
              <div class="flex items-center">
                <b-form-checkbox
                  v-model="isPayment"
                  switch
                />
                <div :class="isPayment ? 'text-green-600' : 'text-[#E31A1A]'">
                  {{ isPayment ? "Aktif" : "Tidak Aktif" }}
                </div>
              </div>
              <div
                class="flex gap-[8px] border-[1px] border-[#E2E2E2] rounded-[8px] w-fit py-[9px] px-[12px]"
              >
                <span class="k-card text-[#626262] text-[20px]" />
                <div class="text-nowrap font-semibold text-[#333333]">
                  {{ cardlabel }}
                </div>
                <div class="text-[#626262]">
                  **{{ lastNumber }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="grid-form mt-[16px]">
          <div>
            <div class="flex items-center gap-[10px]">
              <div>Minimal Saldo</div>
              <div>
                <span
                  id="tooltip-target-1"
                  class="k-info-circle text-[#60b8e8] w-[30px] h-[30px]"
                />
                <b-tooltip
                  variant="light"
                  class="tooltipku"
                  target="tooltip-target-1"
                  triggers="hover"
                >
                  <div class="text-left">
                    Saldo minimum yang memicu auto topup.
                  </div>
                </b-tooltip>
              </div>
            </div>
            <ValidationProvider
              #default="{ errors }"
              name="minimal_saldo"
              :rules="{ required: true, minTransfer: 10000 }"
            >
              <BFormInput
                v-model="minimal_saldo"
                class="mt-[8px]"
                :state="errors.length > 0 ? false : null"
                :disabled="!isPayment"
                @keypress="isNumber($event)"
                @input="inputMinimal"
                @blur="handleBlurCheckTargetAmount"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </div>
          <div>
            <div class="flex items-center">
              <div>Target Topup Saldo</div>
            </div>
            <ValidationProvider
              #default="{ errors }"
              name="target_saldo"
              :rules="{
                required: true,
                minTransfer: RpToInt(minimal_saldo) + 1,
              }"
            >
              <BFormInput
                v-model="target_saldo"
                class="mt-[8px]"
                :disabled="!isPayment"
                :state="errors.length > 0 ? false : null"
                @keypress="isNumber($event)"
                @input="inputTarget"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </div>
        </div>
      </div>
      <div
        class="flex bg-[#DFF3FF] mt-[20px] px-[16px] border-[1px] border-[#0F679A] gap-[10px] py-[10px] rounded-[13px] mx-[24px]"
      >
        <div>
          <img
            class="w-[50px]"
            src="https://storage.googleapis.com/komerce/assets/elements/info-circle.svg"
            alt="info"
          >
        </div>
        <div>
          <div class="text-[#333333] text-[14px] font-semibold">
            Auto Topup lebih mudah!
          </div>
          <div class="mt-[4px] text-[12px] text-[#828282]">
            Setelah saldo kamu mencapai atau dibawah saldo minimal, kartu ini akan di
            topup secara otomatis dengan memotong dari Kompay hingga mencapai target saldo
          </div>
        </div>
      </div>
      <div class="grid grid-cols-2 mt-[24px] gap-[12px] mb-[32px] px-[24px]">
        <BButton
          variant="outline-primary"
          @click="closeButton"
        >
          Batal
        </BButton>
        <BButton
          :id="!invalid && isValidButton ? '' : 'submit-btn'"
          :disabled="invalid || !isValidButton"
          :variant="!invalid && isValidButton ? 'primary' : ''"
          @click="submitTopup"
        >Simpan</BButton>
      </div>
    </ValidationObserver>
  </BModal>
</template>

<script>
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import { isNumber } from '@/libs/helpers'
import { IDR } from '@/libs/currency'
import { required } from '@validations'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import { newAxiosIns } from '@/libs/axios'

extend('minTransfer', {
  validate: (value, args) => {
    const amount = parseFloat(value.replace(/[Rp.p]/g, ''))
    return amount >= args[0]
  },
  message: (field, args) => {
    if (field === 'minimal_saldo') {
      return 'Minimal saldo pada kartu adalah Rp10.000'
    }
    if (field === 'target_saldo') {
      return 'Target saldo harus lebih dari minimal saldo'
    }
    return null
  },
})

extend('maxTransfer', {
  validate: (value, args) => {
    const maxTransfer = args[0]
    const amount = parseFloat(value.replace(/[Rp.p]/g, ''))
    return amount <= maxTransfer
  },
  message: (field, args) => {
    const maxTransfer = args[0]
    return `Saldo yang bisa di transfer sejumlah ${IDR(maxTransfer)}`
  },
})

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    lastNumber: {
      default: null,
      type: String,
    },
    minimalAmount: {
      default: 0,
      type: Number,
    },
    targetAmount: {
      default: 0,
      type: Number,
    },
    id: {
      default: 0,
      type: Number,
    },
    status: {
      default: null,
      type: Boolean,
    },
    cardlabel: {
      default: null,
      type: String,
    },
  },
  data() {
    return {
      isNumber,
      isPayment: null,
      errorLimit: '',
      required,
      target_saldo: null,
      minimal_saldo: null,
    }
  },
  emits: ['refresh-table'],
  computed: {
    isValidButton() {
      if (
        this.RpToInt(this.target_saldo) !== this.targetAmount
        || this.RpToInt(this.minimal_saldo) !== this.minimalAmount
        || this.isPayment !== this.status
      ) {
        return true
      }

      return false
    },
  },
  methods: {
    handleBlurCheckTargetAmount() {
      if (this.RpToInt(this.minimal_saldo) > this.RpToInt(this.target_saldo)) {
        this.$refs.form.setErrors({
          target_saldo: ['Target saldo harus lebih dari minimal saldo'],
        })
      }
    },
    async submitTopup() {
      const url = 'komcards/api/v1/setting/auto-topup'

      try {
        const res = await newAxiosIns.request({
          method: 'post',
          url,
          data: {
            card_id: this.id,
            nominal_target_balance: this.RpToInt(this.target_saldo),
            minimal_balance: this.RpToInt(this.minimal_saldo),
            is_active: this.isPayment,
          },
        })
        const { status } = res

        if (status === 200 || status === 201) {
          this.$toast_success({
            message: `Pengaturan auto topup kartu <b>${this.cardlabel}</b> berhasil disimpan`,
          })
          this.$emit('refresh-table')
          this.$bvModal.hide('modal-auto-topup')
        } else {
          throw new Error()
        }
      } catch (error) {
        const message = 'Terjadi kesalahan sementara saat penerapan auto Topup. Mohon coba lagi dalam beberapa saat'
        this.$toast(
          {
            component: ToastificationContentVue,
            props: {
              title: 'Gagal',
              icon: 'AlertCircleIcon',
              text: message,
              variant: 'danger',
            },
          },
          { timeout: 1000 },
        )
      }
    },
    RpToInt(value) {
      if (value === null) {
        return 0
      }
      const real = parseFloat(value.replace(/[Rp.p]/g, ''))
      if (real > 0) {
        return real
      }

      return 0
    },
    showModal() {
      this.minimal_saldo = `Rp ${this.formatCurrency(`${this.minimalAmount}`)}`
      this.target_saldo = `Rp ${this.formatCurrency(`${this.targetAmount}`)}`
      this.isPayment = this.status
    },
    hideModal() {},
    inputMinimal(e) {
      const format = this.formatCurrency(e)
      this.minimal_saldo = `Rp ${format}`
    },
    inputTarget(e) {
      const format = this.formatCurrency(e)
      this.target_saldo = `Rp ${format}`
    },
    closeButton() {
      this.$bvModal.hide('modal-auto-topup')
    },
    formatCurrency(value) {
      const currency = value.replace(/[Rp. ]/g, '')
      const format = new Intl.NumberFormat('id-ID').format(currency)
      return format
    },
  },
}
</script>

<style scoped>
.grid-modal {
  display: grid;
  grid-template-columns: 1fr 3fr;
  gap: 20px;
}
.grid-form {
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr 1fr;
}
#submit-btn {
  background-color: #f4f4f4 !important;
  border-color: #e2e2e2 !important;
  color: #c2c2c2 !important;
}
.modal-body {
  padding: 0 !important;
}
</style>
