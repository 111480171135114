var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BModal',{ref:"modal-auto-topup",attrs:{"id":"modal-auto-topup","hide-footer":"","size":"md","hide-header":"","centered":"","no-close-on-esc":"","no-close-on-backdrop":"","body-class":"p-0"},on:{"show":_vm.showModal,"hide":_vm.hideModal}},[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('div',{staticClass:"px-[24px] pt-[16px] pb-[10px] border-b-[#E2E2E2]"},[_c('div',{staticClass:"text-[#333333] mt-[10px] font-semibold text-[16px]"},[_vm._v(" Pengaturan Auto Topup ")])]),_c('hr'),_c('div',{staticClass:"px-[24px] mt-[20px]"},[_c('div',{staticClass:"grid-modal"},[_c('div',{staticClass:"flex flex-col gap-[16px]"},[_c('div',{staticClass:"text-nowrap"},[_vm._v(" Set Auto Topup ")]),_c('div',{staticClass:"text-nowrap py-[9px]"},[_vm._v(" Nama kartu ")])]),_c('div',[_c('div',{staticClass:"flex flex-col gap-[16px]"},[_c('div',{staticClass:"flex items-center"},[_c('b-form-checkbox',{attrs:{"switch":""},model:{value:(_vm.isPayment),callback:function ($$v) {_vm.isPayment=$$v},expression:"isPayment"}}),_c('div',{class:_vm.isPayment ? 'text-green-600' : 'text-[#E31A1A]'},[_vm._v(" "+_vm._s(_vm.isPayment ? "Aktif" : "Tidak Aktif")+" ")])],1),_c('div',{staticClass:"flex gap-[8px] border-[1px] border-[#E2E2E2] rounded-[8px] w-fit py-[9px] px-[12px]"},[_c('span',{staticClass:"k-card text-[#626262] text-[20px]"}),_c('div',{staticClass:"text-nowrap font-semibold text-[#333333]"},[_vm._v(" "+_vm._s(_vm.cardlabel)+" ")]),_c('div',{staticClass:"text-[#626262]"},[_vm._v(" **"+_vm._s(_vm.lastNumber)+" ")])])])])]),_c('div',{staticClass:"grid-form mt-[16px]"},[_c('div',[_c('div',{staticClass:"flex items-center gap-[10px]"},[_c('div',[_vm._v("Minimal Saldo")]),_c('div',[_c('span',{staticClass:"k-info-circle text-[#60b8e8] w-[30px] h-[30px]",attrs:{"id":"tooltip-target-1"}}),_c('b-tooltip',{staticClass:"tooltipku",attrs:{"variant":"light","target":"tooltip-target-1","triggers":"hover"}},[_c('div',{staticClass:"text-left"},[_vm._v(" Saldo minimum yang memicu auto topup. ")])])],1)]),_c('ValidationProvider',{attrs:{"name":"minimal_saldo","rules":{ required: true, minTransfer: 10000 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('BFormInput',{staticClass:"mt-[8px]",attrs:{"state":errors.length > 0 ? false : null,"disabled":!_vm.isPayment},on:{"keypress":function($event){return _vm.isNumber($event)},"input":_vm.inputMinimal,"blur":_vm.handleBlurCheckTargetAmount},model:{value:(_vm.minimal_saldo),callback:function ($$v) {_vm.minimal_saldo=$$v},expression:"minimal_saldo"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',[_c('div',{staticClass:"flex items-center"},[_c('div',[_vm._v("Target Topup Saldo")])]),_c('ValidationProvider',{attrs:{"name":"target_saldo","rules":{
              required: true,
              minTransfer: _vm.RpToInt(_vm.minimal_saldo) + 1,
            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('BFormInput',{staticClass:"mt-[8px]",attrs:{"disabled":!_vm.isPayment,"state":errors.length > 0 ? false : null},on:{"keypress":function($event){return _vm.isNumber($event)},"input":_vm.inputTarget},model:{value:(_vm.target_saldo),callback:function ($$v) {_vm.target_saldo=$$v},expression:"target_saldo"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)])]),_c('div',{staticClass:"flex bg-[#DFF3FF] mt-[20px] px-[16px] border-[1px] border-[#0F679A] gap-[10px] py-[10px] rounded-[13px] mx-[24px]"},[_c('div',[_c('img',{staticClass:"w-[50px]",attrs:{"src":"https://storage.googleapis.com/komerce/assets/elements/info-circle.svg","alt":"info"}})]),_c('div',[_c('div',{staticClass:"text-[#333333] text-[14px] font-semibold"},[_vm._v(" Auto Topup lebih mudah! ")]),_c('div',{staticClass:"mt-[4px] text-[12px] text-[#828282]"},[_vm._v(" Setelah saldo kamu mencapai atau dibawah saldo minimal, kartu ini akan di topup secara otomatis dengan memotong dari Kompay hingga mencapai target saldo ")])])]),_c('div',{staticClass:"grid grid-cols-2 mt-[24px] gap-[12px] mb-[32px] px-[24px]"},[_c('BButton',{attrs:{"variant":"outline-primary"},on:{"click":_vm.closeButton}},[_vm._v(" Batal ")]),_c('BButton',{attrs:{"id":!invalid && _vm.isValidButton ? '' : 'submit-btn',"disabled":invalid || !_vm.isValidButton,"variant":!invalid && _vm.isValidButton ? 'primary' : ''},on:{"click":_vm.submitTopup}},[_vm._v("Simpan")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }