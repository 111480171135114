const columnConfig = [
  {
    key: 'card_name',
    label: 'Nama Kartu',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: '#333333',
      minWidth: '150px',
      textAlign: 'left',
      fontWeight: 400,
    },
    tdStyle: {
      textTransform: 'capitalize',
      fontSize: '110px',
      color: '#333333',
      minWidth: '150px',
      textAlign: 'left',
    },
  },
  {
    key: 'is_active',
    label: 'Auto Top Up',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: '#333333',
      minWidth: '150px',
      textAlign: 'center',
      fontWeight: 400,
    },
    tdStyle: {
      textTransform: 'capitalize',
      color: '#333333',
      minWidth: '150px',
      textAlign: 'left',
    },
  },
  {
    key: 'minimal_balance',
    label: 'Minimal Saldo',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: '#333333',
      minWidth: '150px',
      textAlign: 'center',
      fontWeight: 400,
    },
    tdStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: '#333333',
      minWidth: '150px',
      textAlign: 'center',
    },
  },
  {
    key: 'nominal_target_balance',
    label: 'Target Saldo Topup',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: '#333333',
      minWidth: '150px',
      textAlign: 'center',
      fontWeight: 400,
    },
    tdStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: '#333333',
      minWidth: '150px',
      textAlign: 'text',
    },
  },
  {
    key: 'option',
    label: '',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: '#333333',
      minWidth: '150px',
      textAlign: 'center',
      fontWeight: 400,
    },
    tdStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: '#333333',
      minWidth: '150px',
      textAlign: 'center',
      backgroundColor: 'red',
    },
  },
]

export default columnConfig
